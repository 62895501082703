html {
  background-color: #D9D9D9;
}

body {
  background-color: #f4f4f4;
}

.playerTable .MuiDataGrid-columnHeaders,
.playerTable .MuiDataGrid-footerContainer {
  background: #fafafa;
}

.MuiDataGrid-columnHeader {
  margin-right: 1px;
}

.MuiDataGrid-columnHeader--sorted .MuiDataGrid-columnHeaderTitle {
  font-weight: bold;
}

.KeyStatsHeader {
  text-align: center;
  background-color: #595f6a;
  color: white;
  padding: 6px;
}

.KeyStatsTable {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.KeyStatsTable th {
  padding: 10px 0 0 0;
  border: 0;
  width: 25%;
}

.KeyStatsTable th p {
  line-height: 2;
}

.KeyStatsTable tbody td {
  padding: 0 0 10px 0;
  font-weight: 700;
  margin: 0;
  line-height: 1.235;
  border: 0;
}

.RoleSummary table {
  margin-top: 20px;
}

.RoleSummary th {
  background: #ececec;
  padding: 7px;
  border-bottom: 0;
  text-align: right;
  font-weight: 300;
  line-height: 1;
  width: 25%;
}

.RoleSummary td {
  padding: 7px;
  border: 0;
  text-align: right;
}

.RoleSummary tr td:last-child,
th:last-child {
  border-right: none;
}

#ShotChart {
  width: 100%;
  position: relative;
  /*box-shadow: 1px 1px 2px rgb(0 0 0 / 50%);
  background-image: url(./images/wood_light_2.png);
  height: 424px;
  background-size: 30%;*/
}

.MuiInputLabel-outlined.Mui-focused {
  color: #595f6a !important;
}

.MuiOutlinedInput-root.Mui-focused>.MuiOutlinedInput-notchedOutline {
  border-color: #595f6a !important;
}

.PrivatePickersYear-yearButton.Mui-selected {
  background-color: #595f6a !important;
}

#ShotChart {
  font-family: "Roboto Condensed", "Helvetica", "Arial", sans-serif;
}

svg .statLabel {
  font-size: 13px;
  transform: scaleY(0.9);
}

svg .stat {
  font-size: 14px;
  font-weight: bold;
}

svg .statMobile {
  font-size: 15px;
  font-weight: 500;
}

.MuiDataGrid-row {
  padding-bottom: 1px;
}

.MuiDataGrid-cell {
  margin-right: 1px;
}

.scoreHighlight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  padding: 10px 9px 10px 9px;
  margin: -10px;
}

.selectedLeague {
  background: #BF5700 !important;
  color: white !important;
}

.selectedLeague svg {
  color: white;
}

#filter-menu .MuiPaper-root {
  overflow: visible;
}

.MuiDataGrid-selectedRowCount {
  visibility: hidden !important;
}

.favorites-button {
  background-color: transparent;
  opacity: .3;
}

.favorites-button:hover {
  opacity: 1;
}

.saved-button {
  background: "white";
  color: "#BF5700";
  border-color: "#BF5700";
}

.saved-button:disabled {
  background: white;
  color: #BF5700 !important;
  border-color: #BF5700 !important;
}

.saved-button:hover {
  background: white;
  color: #BF5700 !important;
  border-color: #BF5700 !important;
}

.save-notes-button {
  background: #BF5700;
  color: white;
}

.save-notes-button:disabled {
  background: #DCDCDC;
  color: white !important;
}

.menu-mobile .MuiPaper-root {
  height: 100%;
}

[data-amplify-authenticator] {
  /* Fields */
  --amplify-components-fieldcontrol-focus-box-shadow: 0 0 0 2px #BF5700;
  --amplify-components-input-focus-border-color: #BF5700; 

  /* Sign in button */
  --amplify-components-button-primary-background-color: #BF5700;
  --amplify-components-button-primary-hover-background-color: #c97026;
  --amplify-components-button-primary-focus-background-color: #c97026;
  --amplify-components-button-primary-focus-box-shadow: 0 0 0 2px #BF5700;
  --amplify-components-button-primary-active-background-color: #c97026;
  --amplify-components-button-loading-color: white;
  --amplify-components-loader-stroke-filled:#BF5700;

  /* Forgot password button */
  --amplify-components-button-link-color: #BF5700;
  --amplify-components-button-link-hover-background-color: rgba(0, 0, 0, 0.1);
  --amplify-components-button-link-hover-color: #BF5700;
  --amplify-components-button-link-focus-box-shadow: 0 0 0 2px #BF5700;
  --amplify-components-button-link-focus-background-color: rgba(0, 0, 0, 0.1);
  --amplify-components-button-link-focus-color: #BF5700;
  --amplify-components-button-link-active-background-color: rgba(0, 0, 0, 0.3);
  --amplify-components-button-link-active-color: #BF5700;

  /* Show password button */
  --amplify-components-passwordfield-button-focus-color:  #BF5700;
  --amplify-components-passwordfield-button-focus-border-color:  #BF5700;
  --amplify-components-passwordfield-button-hover-background-color:  rgba(0, 0, 0, 0.1);
  --amplify-components-passwordfield-button-hover-color:  #BF5700;
  --amplify-components-passwordfield-button-hover-border-color:rgb(137, 148, 159);
  --amplify-components-passwordfield-button-active-border-color:#BF5700;
  --amplify-components-passwordfield-button-active-color:#BF5700;
  --amplify-components-passwordfield-button-active-background-color:rgba(0, 0, 0, 0.3);
}
